import axios from 'axios'

const currentUrl = window.location.href
let url = ''

if (currentUrl.includes('onclubapp.com.br')) {
  url = 'https://api.onclubapp.com.br/api/v1/'
} else if (currentUrl.includes(':300')) {
  url = 'https://api.onclubapp.com.br/api/v1/'
}

const provider = axios.create({
  baseURL: url,
})

export default provider
