import toast from 'react-hot-toast'

class Toast {
  static show(message, type = 'error') {
    switch (type) {
      case 'success':
        toast.success(
          () => (
            // eslint-disable-next-line react/react-in-jsx-scope
            <div>
              {message.split('\n').map((text) => (
                // eslint-disable-next-line react/jsx-key, react/react-in-jsx-scope
                <p>{text}</p>
              ))}
            </div>
          ),
          {
            duration: 4000,
            position: 'top-right',
            style: {
              borderLeft: '12px solid var(--primary)',
              minHeight: 60,
              borderRadius: 4,
            },
          },
        )
        break
      case 'error':
        toast.error(
          () => (
            // eslint-disable-next-line react/react-in-jsx-scope
            <div>
              {message.split('\n').map((text) => (
                // eslint-disable-next-line react/jsx-key, react/react-in-jsx-scope
                <p>{text}</p>
              ))}
            </div>
          ),
          {
            duration: 4000,
            position: 'top-right',
            style: {
              borderLeft: '12px solid var(--primary)',
              minHeight: 60,
              borderRadius: 4,
            },
          },
        )
        break
      default:
        break
    }
  }
}

export default Toast
