import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { useAuth } from './contexts/auth'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// eslint-disable-next-line react/prop-types
function AccessRoute({ type, Component, children, ...rest }) {
  const { isAuthenticated } = useAuth()

  if (type === 'auth') {
    if (!isAuthenticated) {
      return children
    }
    return <Navigate to="/" />
  }
  if (isAuthenticated) return children
  return <Navigate to="/login" />
}

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              element={
                <AccessRoute type="auth">
                  <Login />
                </AccessRoute>
              }
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={
                <AccessRoute type="auth">
                  <Register />
                </AccessRoute>
              }
            />
            <Route
              path="*"
              name="Home"
              element={
                <AccessRoute>
                  <DefaultLayout />
                </AccessRoute>
              }
            />
            {/* <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
