import provider from './config'

export async function login(payload) {
  try {
    const response = await provider.post('autenticacao/', payload)
    return response.data
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message)
    }

    throw Error('Erro ao logar')
  }
}
