import React, { createContext, useContext, useState, useEffect } from 'react'
import Toast from 'src/components/toast/Toast'
import * as authService from '../services/auth.js'

const AuthContext = createContext({
  isAuthenticated: null,
  user: null,
  signIn: null,
  signOut: null,
})

// eslint-disable-next-line react/prop-types
function AuthProvider({ children }) {
  const [user, setUser] = useState(null)

  useEffect(() => {
    retrieveUser()
  }, [])

  function saveUser(user) {
    setUser(user)
    localStorage.setItem('@onclub-app', JSON.stringify(user))
  }

  function retrieveUser() {
    const localUser = localStorage.getItem('@onclub-app')
    if (localUser) {
      return setUser(JSON.parse(localUser))
    }
  }

  async function signIn(payload) {
    try {
      const result = await authService.login(payload)
      if (result) {
        saveUser({ token: result.token, ...result })
      }
      return 'OK'
    } catch (err) {
      Toast.show(`${err.message}`, 'error')
      return 'Erro'
    }
  }

  function signOut() {
    setUser(null)
    return localStorage.removeItem('@onclub-app')
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}

export default AuthProvider
